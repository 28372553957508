<template>
  <component
    :is="currentTaskComponent"
    :task="activeTask"
    :is-loading-task="activeTaskIsLoading"
    @on-task-complete="onTaskComplete"
    @on-task-skip="onTaskSkip"
  >
    <template v-if="!shouldPlaySingleTask" #play-mode-indicator>
      <div class="play-mode-title">
        <div class="play-icon-bg">
          <PlayIcon class="play-icon" :rotate="true" :size="16" />
        </div>
        {{ $t('tasks.tasksPage.playMode.tasksLeft', { count: tasksTotalCount }) }}
      </div>
    </template>
  </component>
</template>

<script type="text/javascript">
import { computed, watch } from 'vue';
import { useActiveTask } from '../compositions/useActiveTask';
import { debounce } from 'lodash';
import { useRoute, useRouter } from 'vue-router/composables';
import DefineProductTask from '@/modules/product/components/task/DefineProductTask.vue';
import MapProductsTask from '@/modules/product/components/task/MapProductsTask.vue';
import { Tag } from '@/modules/core';
import {
  AllocationTask,
  ReceiveReturnGoodsTask,
  BalanceAlignmentTaskSingularLayout,
  ReconciliationTaskSingularLayout,
  UncertainBillingTaskSingularLayout,
} from '@/modules/tasks/task';
import { PlayIcon } from '@/assets/icons';

const taskComponentMap = {
  define_product: DefineProductTask,
  allocation_billing: AllocationTask,
  declare_delivery: ReceiveReturnGoodsTask,
  map_product: MapProductsTask,
  balanceAlignment_reconciliation: BalanceAlignmentTaskSingularLayout,
  handleReconciliation_reconciliation: ReconciliationTaskSingularLayout,
  uncertainBilling_billing: UncertainBillingTaskSingularLayout,
};

export default {
  name: 'PlayMode',
  components: { Tag, PlayIcon },
  props: {
    taskId: { type: String, default: null },
    businessIds: { type: Array, required: true },
    domains: { type: Array, required: true },
    types: { type: Array, required: true },
    reviewRequired: { type: Boolean },
    tasksTotalCount: { type: Number, default: null },
    shouldPlaySingleTask: { type: Boolean, default: false },
  },
  emits: ['on-task-complete', 'on-task-skip', 'hide-play-mode'],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const taskId = computed(() => props.taskId);
    const shouldPlaySingleTask = computed(() => props.shouldPlaySingleTask);

    const { activeTask, refetchActiveTask, activeTaskIsLoading } = useActiveTask(
      computed(() => ({
        taskId: shouldPlaySingleTask.value && taskId.value ? taskId.value : null,
        businessIds: props.businessIds,
        domains: props.domains,
        types: props.types,
        reviewRequired: props.reviewRequired,
        completed: false,
      }))
    );

    const currentTaskComponent = computed(() => {
      if (!activeTask.value) return null;

      const { domain, type } = activeTask.value;

      return taskComponentMap[`${type}_${domain}`] || null;
    });

    watch(
      [activeTask, taskId],

      ([newActiveTask, newTaskId], [oldActiveTask, oldTaskId]) => {
        const query = {
          ...route.query,
        };
        if (newTaskId && newTaskId !== oldTaskId) {
          query.task = newTaskId;
          router.replace({ query });
        } else if (
          oldActiveTask?.id !== newActiveTask?.id &&
          newActiveTask?.id &&
          (newActiveTask?.activeAt <= Date.now() || shouldPlaySingleTask.value)
        ) {
          query.task = newActiveTask.id;
          router.replace({ query });
        } else if (!newTaskId && !newActiveTask?.id) {
          delete query.task;
          router.replace({ query });
        }
      },
      { immediate: true, deep: true }
    );

    const deleteTaskFromQueryParams = () => {
      const query = {
        ...route.query,
      };

      delete query.task;

      router.push({ query });
    };

    watch(
      activeTask,
      (newValue, oldValue) => {
        if (!newValue) {
          deleteTaskFromQueryParams();
        } else if (
          !shouldPlaySingleTask.value &&
          oldValue?.id !== newValue?.id &&
          oldValue?.activeAt !== newValue?.activeAt &&
          newValue?.activeAt > Date.now()
        ) {
          emit('hide-play-mode');
        }
      },
      { deep: true }
    );

    const onTaskSkipComplete = (eventEmit) => {
      if (shouldPlaySingleTask.value) {
        deleteTaskFromQueryParams();
      } else {
        refetchActiveTask();
      }

      if (eventEmit) {
        emit(eventEmit);
      }
    };

    const onTaskComplete = debounce(() => onTaskSkipComplete('on-task-complete'));
    const onTaskSkip = debounce(() => onTaskSkipComplete('on-task-skip'));

    return {
      currentTaskComponent,
      activeTask,
      onTaskComplete,
      onTaskSkip,
      activeTaskIsLoading,
    };
  },
};
</script>

<style lang="scss">
.play-mode-title {
  display: flex;
  align-items: center;
  font-size: 12px;

  .play-icon-bg {
    background-color: #f3f3f4;
    border-radius: 50%;
    width: 21px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;

    .play-icon {
      margin-left: -1px;
    }
  }
}
</style>
