<template>
  <el-dialog append-to-body visible :before-close="close" top="2%">
    <template #title>
      <div class="d-flex align-items-center">
        <AdditionalNamesIcon width="14" height="14" :class="$direction === 'rtl' ? 'ms-2' : 'me-2'" />
        <h2>{{ $t('tasks.task.product.confirmationModal.title') }}</h2>
      </div>
    </template>
    <div class="p-4 mb-4 d-flex align-items-center products-merge-warning-background">
      <WarningIcon width="24" height="24" class="text-danger" :class="$direction === 'rtl' ? 'ms-1' : 'me-1'" />
      <p class="fw-bold text-danger">
        {{ $t('tasks.task.product.confirmationModal.description') }}
      </p>
    </div>
    <p class="mb-4">{{ $t('tasks.task.product.confirmationModal.sourceProduct') }}</p>
    <div class="mb-5 card p-1">
      <Table :columns="columns" :data="[sourceProduct]" />
    </div>
    <p class="mb-4">{{ $t('tasks.task.product.confirmationModal.productsToMerge') }}</p>
    <div class="mb-5 card p-1">
      <Table :columns="columns" :data="productsToMerge" />
    </div>
    <div class="p-4 mb-5 bg-light">
      <p class="mb-4 fw-bold">{{ $t('tasks.task.product.confirmationModal.footerDescription') }}</p>
      <el-form ref="confirmationForm" :model="formModel" :show-message="false">
        <el-form-item
          prop="confirmationText"
          :rules="{ required: true, pattern: $t('tasks.task.product.confirmationModal.productsMerge') }"
        >
          <el-input v-model="formModel.confirmationText" :placeholder="$t('commons.type')" />
        </el-form-item>
      </el-form>
    </div>
    <div class="d-flex justify-content-end">
      <Button type="secondary" @click="close">{{ $t('commons.cancel') }}</Button>
      <Button type="primary" @click="handleMergeProducts">
        {{ $t('tasks.task.product.confirmationModal.productsMergeApproval') }}</Button
      >
    </div>
  </el-dialog>
</template>

<script>
import { getCurrentInstance } from 'vue';
import { useMutation } from '@vue/apollo-composable';

import { WarningIcon, AdditionalNamesIcon } from '@/assets/icons';
import { Button, Table } from '@/modules/core/components';

import { PRODUCT_MERGE_MUTATION } from './graphql';

const TABLE_HEADERS = {
  SKU: 'sku',
  NAME: 'name',
};

export default {
  components: { Button, WarningIcon, AdditionalNamesIcon, Table },
  props: {
    sourceProduct: {
      type: Object,
      required: true,
    },
    productsToMerge: {
      type: Array,
      required: true,
    },
  },
  setup(_, { emit }) {
    const root = getCurrentInstance().proxy;
    const {
      mutate: mergeProducts,
      onError: mergeProductsOnError,
      onDone: mergeProductsOnDone,
    } = useMutation(PRODUCT_MERGE_MUTATION);

    mergeProductsOnDone(() => {
      root.$message.success(root.$i18n.t('product.notification.productLinkSuccess'));
      emit('productsMerged');
      emit('close');
    });

    mergeProductsOnError(() => root.$message.error(root.$i18n.t('product.notification.productLinkError')));

    return {
      mergeProducts,
    };
  },
  data() {
    return {
      formModel: {
        confirmationText: '',
      },
    };
  },
  computed: {
    columns() {
      return [
        { header: this.$t('commons.sku'), key: TABLE_HEADERS.SKU, width: '140px' },
        { header: this.$t('tasks.task.product.table.productName'), key: TABLE_HEADERS.NAME },
      ];
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleMergeProducts() {
      this.$refs.confirmationForm.validate((valid) => {
        if (valid) {
          const productIds = [
            ...new Set([this.sourceProduct.productId, ...this.productsToMerge.map((product) => product.productId)]),
          ];
          this.mergeProducts({ ids: productIds });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
.products-merge-warning-background {
  background: #fff5f7;
}
</style>
