<template>
  <div class="text">
    {{ customerName }}
    <ExchangeIcon class="mx-1" />
    {{ supplierName }}
  </div>
</template>

<script>
import { ExchangeIcon } from '@/assets/icons';

export default {
  components: { ExchangeIcon },
  props: {
    supplierName: { type: String, required: true },
    customerName: { type: String, required: true },
  },
};
</script>

<style scoped>
.text {
  font-size: 16px;
}
</style>
