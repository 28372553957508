<template>
  <DropdownTableFilter
    trigger="click"
    class="search-multi-select"
    :hide-on-click="false"
    :filter-name="title"
    :clearable="false"
    :placement="$direction === 'rtl' ? 'bottom-end' : 'bottom-start'"
    @on-choose-item="onSelect"
  >
    <MultiSelectDropdown
      v-bind="$props"
      slot="dropdown"
      @on-select="(selected) => $emit('on-select', selected)"
      @on-search-change="(searchText) => $emit('on-search-change', searchText)"
      @load-more="$emit('load-more')"
    />
  </DropdownTableFilter>
</template>

<script>
import { DropdownTableFilter } from '@/modules/core';

import MultiSelectDropdown from './MultiSelectDropdown.vue';

export default {
  name: 'MultiSelect',
  components: { MultiSelectDropdown, DropdownTableFilter },
  props: {
    title: { type: String, required: true },
    options: { type: Array, required: true },
    selected: { type: Array, required: false, default: () => [] },
    placeholder: { type: String, default: 'Search...' },
    searchable: { type: Boolean, default: true },
    width: { type: String, default: '359px' },
  },
  emits: ['on-select', 'on-search-change', 'load-more'],
  setup(props, { emit }) {
    const onSelect = (option) => {
      const selected = [...props.selected];
      const index = selected.findIndex((item) => item.id === option.id);
      if (index === -1) {
        selected.push(option);
      } else {
        selected.splice(index, 1);
      }
      emit('on-select', selected);
    };

    return {
      onSelect,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import './style';

.scrollable {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;

  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.search-icon {
  margin-top: 8px;
  width: 1rem;
  height: 1rem;
  margin-right: 4px;
}

.search-multi-select-dropdown {
  max-height: 287px;
  overflow-y: auto;
}

.el-dropdown-menu {
  padding-top: 0px;
  padding-bottom: 0px;
}

.el-divider {
  margin: 0px;
}

:deep(.el-checkbox__label) {
  font-size: 14px;
  font-weight: 400;
}

:deep(input[popper-class='search-filter-input']) {
  border: transparent;
  height: 32px;
}
:deep(i.el-icon-circle-close.el-input__clear) {
  margin-top: -2px;
  width: 1rem;
  height: 1rem;
}
</style>
