<template>
  <el-dropdown-menu slot="dropdown" :style="multiSelectStyle" class="search-multi-select-dropdown">
    <el-input
      v-if="searchable"
      v-model="searchText"
      popper-class="search-filter-input"
      :placeholder="placeholder"
      :clearable="true"
      @input="handleSearchTextChange"
      @clear="handleSearchTextClear"
    >
      <template #prefix>
        <SearchIcon class="search-icon" />
      </template>
    </el-input>
    <el-divider />

    <div :class="searchable ? 'scrollable' : ''" @scroll="handleScroll">
      <el-dropdown-item v-if="selectAll" key="choose-all" command="all">
        <el-checkbox
          key="search-multi-select-checkbox-choose-all"
          label="בחר הכל"
          :indeterminate="isSelectAllIndeterminate"
          :value="isAllSelected"
          @change="onSelect('all')"
        >
        </el-checkbox>
      </el-dropdown-item>
      <el-dropdown-item v-for="option in options" :key="option.id + title" :command="option">
        <el-checkbox
          :key="`search-multi-select-checkbox-${option.id}`"
          :label="option.name"
          :value="isSelected(option.id)"
          @change="onSelect(option)"
        >
        </el-checkbox>
      </el-dropdown-item>
    </div>
    <div v-if="submitButtons" class="d-flex gap-1 justify-content-end">
      <el-button type="secondary" @click="onCancel">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="onSubmit">{{ $t('commons.save') }}</el-button>
    </div>
  </el-dropdown-menu>
</template>

<script>
import { computed, watch, ref, toRefs } from 'vue';
import { SearchIcon } from '@/assets/icons';

export default {
  name: 'MultiSelectDropdown',
  components: { SearchIcon },
  props: {
    title: { type: String, required: true },
    options: { type: Array, required: true },
    selected: { type: Array, required: false, default: () => [] },
    placeholder: { type: String, default: 'Search...' },
    searchable: { type: Boolean, default: true },
    width: { type: String, default: '359px' },
    submitButtons: { type: Boolean, default: false },
    selectAll: { type: Boolean, default: false },
  },
  emits: ['on-select', 'on-search-change', 'load-more'],
  setup(props, { emit }) {
    const searchText = ref('');
    const selected = toRefs(props).selected;
    const options = toRefs(props).options;
    const isAllSelected = ref(false);

    const multiSelectStyle = computed(() => ({
      width: props.width,
    }));

    const handleScroll = (event) => {
      if (!props.searchable) {
        return;
      }

      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const bottomDistance = scrollHeight - scrollTop - clientHeight;
      if (bottomDistance === 0 && props.options.length !== props.totalCount) {
        emit('load-more');
      }
    };

    const handleSearchTextChange = (value) => {
      searchText.value = value || '';
      emit('on-search-change', searchText.value);
    };

    const handleSearchTextClear = () => {
      searchText.value = '';
      emit('on-search-change', searchText.value);
    };

    watch(
      selected,
      () => {
        isAllSelected.value = options.value.length ? selected.value.length === options.value.length : false;
      },
      { immediate: true, deep: true }
    );

    const onSelect = (option) => {
      let selected;
      if (option === 'all') {
        if (isAllSelected.value) {
          selected = [];
          isAllSelected.value = false;
        } else {
          selected = props.options;
          isAllSelected.value = true;
        }
      } else {
        selected = [...props.selected];
        const index = selected.findIndex((item) => item.id === option.id);
        if (index === -1) {
          selected.push(option);
        } else {
          selected.splice(index, 1);
        }
        isAllSelected.value = selected.length === props.options.length;
      }
      emit('on-select', selected);
    };

    const isSelectAllIndeterminate = computed(() =>
      isAllSelected.value ? false : props.selected.some((item) => props.options.includes(item))
    );

    const isSelected = (optionId) => {
      return props.selected.some((item) => item.id === optionId);
    };

    return {
      searchText,
      isAllSelected,
      multiSelectStyle,
      onSelect,
      isSelected,
      handleScroll,
      handleSearchTextClear,
      handleSearchTextChange,
      isSelectAllIndeterminate,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import './style';

.scrollable {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;

  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.search-icon {
  margin-top: 8px;
  width: 1rem;
  height: 1rem;
  margin-right: 4px;
}

.search-multi-select-dropdown {
  max-height: 287px;
  overflow-y: auto;
}

.el-dropdown-menu {
  padding-top: 0px;
  padding-bottom: 0px;
}

.el-divider {
  margin: 0px;
}

:deep(.el-checkbox__label) {
  font-size: 14px;
  font-weight: 400;
}

:deep(input[popper-class='search-filter-input']) {
  border: transparent;
  height: 32px;
}
:deep(i.el-icon-circle-close.el-input__clear) {
  margin-top: -2px;
  width: 1rem;
  height: 1rem;
}
</style>
